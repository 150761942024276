<template>
  <div
    v-if="!isLoading"
    class="auth-wrapper auth-v1"
  >
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/login"
            class="d-flex align-center"
          >
            <v-img
              :src="url_public+config.logo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ config.name }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Documentación de la API
          </p>
          <p class="mb-2">
            Crendenciales de acceso
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="model.api_key"
              outlined
              label="API KEY"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="model.secret_token"
              outlined
              label="SECRET TOKEN"
              hide-details
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forgot link -->
              <span style="color: #fff">.</span>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading"
              @click="login()"
            >
              {{ $t('btn.send') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- PROTURS -->
    <ProIzq
      v-if="name_project === 'proturs'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <ProDerecha
      v-if="name_project === 'proturs'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <!-- KRONOS -->
    <KroIzq
      v-if="name_project === 'kronos'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
    <KroDerecha
      v-if="name_project === 'kronos'"
      class="auth-tree-3"
      width="250"
      height="180"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

// eslint-disable-next-line import/no-unresolved
import ProDerecha from '@/views/utils/images/pro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroDerecha from '@/views/utils/images/kro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'

export default {
  components: {
    ProDerecha,
    ProIzq,
    KroDerecha,
    KroIzq,
  },

  data() {
    return {
      appLogo: themeConfig.app.logo,
      config: {
        logo: null,
        secret_token: null,
      },
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      model: {
        api_key: null,
        secret_token: null,
      },
      loading: false,
    }
  },
  mounted() {
    if (this.$route.query.api_key) {
      this.model.api_key = this.$route.query.api_key
    }
    if (this.$route.query.secret_token) {
      this.model.secret_token = this.$route.query.secret_token
    }
    this.load()

    // console.log(this.$vuetify.theme.themes.light.primary)
  },
  methods: {
    load() {
      this.axios
        .get('configuration-front/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    login() {
      // eslint-disable-next-line no-empty
      if (this.model.api_key && this.model.secret_token) {
        this.loading = true
        // eslint-disable-next-line no-undef
        this.axios
          .post('doc/login', this.model)
          .then(response => {
            if (response.data.success === false) {
              if (response.data.status === 404 || response.data.status === 400) {
                this.$toast.error(this.$t('msg.invalidCredentials'))
              } else if (response.data.status === 401) {
                this.$toast.error(this.$t('msg.userInactive'))
              }
            } else {
              sessionStorage.setItem('api_key', this.model.api_key)
              sessionStorage.setItem('secret_token', this.model.secret_token)
              sessionStorage.setItem('permisos_auth', response.data.data.permisos)

              this.$router.push({ name: 'dashboard' })

              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          })
          .catch(error => console.log(error))

          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.dataRequiere'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
